class ActionListElement extends HTMLElement {
  connectedCallback(): void {
    const items = this.querySelectorAll('li')

    for (const item of items) {
      const subscriptions = [
        fromEvent(item, 'click', e => handleClick(item, e)),
        fromEvent(item, 'keydown', e => handleKeydown(item, e))
      ]

      states.set(item, subscriptions)
    }
  }

  disconnectedCallback(): void {
    const items = this.querySelectorAll('li')
    for (const item of items) {
      const state = states.get(item)
      if (!state) return
      states.delete(this)
      for (const sub of state.subscriptions) {
        sub.unsubscribe()
      }
    }
  }
}

function handleClick(item: Element, e: Event) {
  if (hasPopup(item)) {
    toggleItem(item)
  }

  if (isHighlightable(item)) {
    updateActiveItem(item)
  }

  e.stopPropagation()
}

function isHighlightable(item: Element): boolean {
  return item.getAttribute('role') !== 'none' && !hasPopup(item)
}

function updateActiveItem(activeItem: Element) {
  const itemsToDeactivate = document.querySelectorAll('.ActionList-item--navActive')

  for (const listItem of itemsToDeactivate) {
    removeActiveHighlight(listItem)
  }

  addActiveHighlight(activeItem)
}

function addActiveHighlight(item: Element) {
  item.classList.add('ActionList-item--navActive')
  item.setAttribute('aria-current', 'location')
}

function removeActiveHighlight(item: Element) {
  item.classList.remove('ActionList-item--navActive')
  item.removeAttribute('aria-current')
}

function hasPopup(item: Element): boolean {
  return item.getAttribute('aria-haspopup') === 'true'
}

function toggleItem(item: Element) {
  if (item.getAttribute('aria-expanded') === 'true') {
    item.setAttribute('aria-expanded', 'false')
  } else {
    item.setAttribute('aria-expanded', 'true')
  }
}

function handleKeydown(item: Element, event: Event) {
  if (!(event instanceof KeyboardEvent)) return

  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable no-restricted-syntax */
  switch (event.key) {
    case 'Enter': {
      if (hasPopup(item)) {
        toggleItem(item)
        const firstSubItem = item.querySelector('ul li') as HTMLElement
        firstSubItem.setAttribute('tabindex', '-1')
        firstSubItem.focus()
      } else {
        item.querySelector('a')?.click()
      }
      break
    }
  }
  /* eslint-enable no-restricted-syntax */
}

const states = new WeakMap()

type Subscription = {unsubscribe(): void}

function fromEvent(
  target: EventTarget,
  eventName: string,
  onNext: EventListenerOrEventListenerObject,
  options: boolean | AddEventListenerOptions = false
): Subscription {
  target.addEventListener(eventName, onNext, options)
  return {
    unsubscribe: () => {
      target.removeEventListener(eventName, onNext, options)
    }
  }
}

// Necessary to turn this file into an external module, which enables the Window
// interface modifications below.
export {}

declare global {
  interface Window {
    ActionListElement: typeof ActionListElement
  }
  interface HTMLElementTagNameMap {
    'action-list': ActionListElement
  }
}

if (!window.customElements.get('action-list')) {
  window.ActionListElement = ActionListElement
  window.customElements.define('action-list', ActionListElement)
}

// On page load, set "active" style on correct ActionList item if its content
// is the active target (based on the fragment identifier of the URL).
if (window.location.hash !== '') {
  const listItems = document.querySelectorAll('.ActionList-item')

  for (const listItem of listItems) {
    if (!isHighlightable(listItem)) continue

    const listItemContentLink = listItem.querySelector<HTMLAnchorElement>('.ActionList-content')
    if (!listItemContentLink) continue
    if (listItemContentLink.hash !== window.location.hash) continue

    addActiveHighlight(listItem)
  }
}

// Removes "active" styles from menu items when the fragment identifier of the
// URL, aka the "hash", has been removed.
window.addEventListener('hashchange', function () {
  if (window.location.hash !== '') return

  const activeItems = document.querySelectorAll('.ActionList-item--navActive')
  if (activeItems.length === 0) return

  for (const activeItem of activeItems) {
    removeActiveHighlight(activeItem)
  }
})
